<template>
  <div>
    <v-layout wrap>
      <v-flex xs12 pt-4>

        
    <v-layout v-for="item in bookingData" :key="item._id" hidden-sm-and-down>
      <v-flex md12 pt-4>
        <!-- <router-link :to="'/Booking/paymentDetails?id=' + item.payment.ticket"> -->
        <v-card flat>
          <!-- <div
            :class="
              item.payment.verificationStatus == 'Verified' ? 'line1' : ''
            "
          >
            <span
              v-if="item.payment.verificationStatus == 'Verified'"
              style="
                color: white;
                font-size: 13.5px;
                font-family: poppinssemibold;
                letter-spacing: 2px;
                background-color: red;
                padding-right: 20px;
                padding-left: 20px;
              "
            >
              Verified
            </span>
          </div> -->
          <v-layout wrap pt-3 text-left>
            <v-flex xs12 sm12 md7 lg6 xl8>
              <span style="font-size: 16px; font-family: poppinssemibold"
                >Ticket Number:</span
              >
              <span
                style="
                  font-size: 16px;
                  font-family: poppinsbold;
                  color: #68d389;
                "
              >
                #{{ item.data.ticket.ticketNo }}</span
              >
            </v-flex>
            <!-- <v-flex xs12 sm6 md6 lg6 text-end>
              <span>
                <a
                  target="_blank"
                  :href="
                    'https://api.periyartigerreserve.org/booking/ticketpdf/?id=' +
                    item.payment._id
                  "
                  style="color: #68d389"
                >
                  <v-icon color="#68d389" size="16">
                    mdi-arrow-collapse-down
                  </v-icon>
                  Download Ticket
                </a>
              </span>
            </v-flex> -->
             <v-flex xs12 sm6 md5 lg6 xl4 text-end>
            <v-layout wrap justify-center>
                <v-flex xs6 sm4 md9 xl10 lg10 pr-1 pr-md-0 pr-lg-1 pr-xl-4 text-right >
                  <span style="font-size: 16px; font-family: poppinssemibold"
                    >Payment Date :</span
                  >
                </v-flex>
                <v-flex xs6 sm8 md3 xl2 lg2>
                  <span
                    style="
                      font-size: 16px;
                      font-family: poppinsbold;
                      color: #68d389;
                    "
                  >
                    {{ item.data.init_date.slice(0, 10) }}</span
                  >
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout
            wrap
            pb-2
            v-for="pgm in item.details"
            :key="pgm._id"
          >
            <v-flex xs12 md9 pb-1 text-left>
              <span
                style="font-family: poppinsbold; font-size: 18px; color: red"
              >
                {{ pgm.programme.progName }}
              </span>
            </v-flex>
            <v-flex xs12 md3 text-right pb-1>
              <v-layout wrap justify-center>
                <v-flex xs6 sm4 md8 xl9 lg8 pr-1>
                  <span style="font-size: 16px; font-family: poppinssemibold"
                    >Total Amount :</span
                  >
                </v-flex>
                <v-flex xs6 sm8 md4 xl3 lg4>
                  <span
                    style="
                      font-size: 16px;
                      font-family: poppinsbold;
                      color: #68d389;
                    "
                  >
                    INR {{ pgm.payment.total }}</span
                  >
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex md12>
              <v-card style="min-height: 100px" outlined>
                <v-layout
                  style="
                    font-family: poppinsregular;
                    font-size: 13px;
                    background-color: black;
                    color: white;
                  "
                  wrap
                  pa-2
                  justify-start
                >
                  <v-flex xs2> Name </v-flex>
                  <!-- <v-flex> Email </v-flex> -->
                  <v-flex xs1> Phone </v-flex>
                  <v-flex xs1 text-right> Date of Birth</v-flex>
                  <v-flex xs1 text-center> Gender </v-flex>
                  <v-flex xs2> Type</v-flex>
                  <v-flex xs1 text-center> Booking Date</v-flex>
                  <v-flex text-center xs2> ID Proof </v-flex>
                  <v-flex xs2> Timing </v-flex>
                </v-layout>
                <v-layout
                  v-for="guestItem in pgm.guest"
                  :key="guestItem._id"
                  style="font-family: poppinsbold; font-size: 13px"
                  wrap
                  pa-2
                  justify-start
                >
                  <v-flex xs2>{{ guestItem.name }} </v-flex>
                  <!-- <v-flex> {{guestItem.guest.email}}</v-flex> -->
                  <v-flex xs1> {{ guestItem.phoneno }}</v-flex>
                  <v-flex xs1 text-right>
                    {{ guestItem.dob.slice(0, 10) }}</v-flex
                  >
                  <v-flex xs1 text-center>
                    {{ guestItem.gender }}
                  </v-flex>
                  <v-flex xs2> {{ guestItem.guestType }} </v-flex>
                  <v-flex xs1 text-center>
                    {{ pgm.bookingDate.slice(0, 10) }}</v-flex
                  >
                  <v-flex xs2 text-center>
                    <span v-if="guestItem.idproofs">{{
                      guestItem.idproofs.idNumber
                    }}</span>
                    <span v-else>Nil</span>
                  </v-flex>
                  <v-flex xs2>
                    {{ formatTime(pgm.slotDetail.startTime) }} -
                    {{ formatTime(pgm.slotDetail.endTime) }}</v-flex
                  >
                </v-layout>
                <!-- <v-layout wrap justify-end>
                  <v-flex xs6 sm4 lg2 align-self-end text-right>
                    <v-btn
                      plain
                      class="text-capitalize"
                      @click="
                        getFullOrder(
                          pgm.programme.progName,
                          item.payment.ticket
                        )
                      "
                      color="red"
                      >Cancel(Full Refund)</v-btn
                    ></v-flex
                  >
                  <v-flex xs6 sm4 lg2 align-self-end text-right>
                    <v-btn
                      plain
                      class="text-capitalize"
                      @click="
                        getOrder(pgm.programme.progName, item.payment.ticket)
                      "
                      color="red"
                      >Cancel Booking</v-btn
                    ></v-flex
                  >
                </v-layout> -->
              </v-card>
            </v-flex>
          </v-layout>
        </v-card>
        <!-- </router-link> -->
      </v-flex>
    </v-layout>

    
    <v-layout v-for="(items, index1) in bookingData" :key="index1" hidden-md-and-up>
      <v-flex md12 pt-4>
        <!-- <router-link :to="'/Admin/paymentDetails?id=' + items.payment.ticket"> -->
          <v-card flat>
            <v-layout wrap pt-3 text-left>
              <v-flex xs12 sm12 md12 lg12 xl12>
                <span style="font-size: 16px; font-family: poppinssemibold"
                  >Ticket Number:</span
                >
                <span
                  style="
                    font-size: 16px;
                    font-family: poppinsbold;
                    color: #68d389;
                  "
                >
                  #{{ items.data.ticket.ticketNo }}</span
                >
              </v-flex>
            </v-layout>
            <v-layout
              wrap
              v-for="pgm1 in items.details"
              :key="pgm1.programme.index3"
            >
              <v-flex xs12 pb-2 text-left>
                <span
                  style="font-family: poppinsbold; font-size: 18px; color: red"
                >
                  {{ pgm1.programme.progName }}
                </span>
              </v-flex>
              <v-flex xs12>
                <v-card>
                  <v-expansion-panels>
                    <v-expansion-panel
                      v-for="guestItem1 in pgm1.guest"
                      :key="guestItem1._id"
                      style="font-family: poppinsbold; font-size: 13px"
                      wrap
                      pa-2
                      justify-start
                    >
                      <v-expansion-panel-header>
                        <v-layout wrap justify-center>
                          <v-flex xs6>{{ guestItem1.name }} </v-flex>
                          <v-flex xs6>
                            {{ formatTime(pgm1.slotDetail.startTime) }}
                            -
                            {{
                              formatTime(pgm1.slotDetail.endTime)
                            }}</v-flex
                          >
                        </v-layout>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-layout
                          style="font-family: poppinsregular; font-size: 13px"
                          wrap
                          pa-2
                          justify-start
                        >
                          <v-flex xs6 sm3>
                            <v-layout wrap justify-center>
                              <v-flex xs12 class="textField2"> Phone </v-flex>
                              <v-flex xs12 class="textField1">
                                {{ guestItem1.phoneno }}
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs6 sm3>
                            <v-layout wrap justify-center>
                              <v-flex xs12 class="textField2">
                                Date of Birth
                              </v-flex>
                              <v-flex xs12 class="textField1">
                                {{ guestItem1.dob.slice(0, 10) }}
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs6 sm3>
                            <v-layout wrap justify-center>
                              <v-flex xs12 class="textField2"> Gender </v-flex>
                              <v-flex xs12 class="textField1">
                                {{ guestItem1.gender }}
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs6 sm3>
                            <v-layout wrap justify-center>
                              <v-flex xs12 class="textField2"> Type </v-flex>
                              <v-flex xs12 class="textField1">
                                {{ guestItem1.guestType }}
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs6 sm3>
                            <v-layout wrap justify-center>
                              <v-flex xs12 class="textField2">
                                ID Proof
                              </v-flex>
                              <v-flex
                                xs12
                                v-if="guestItem1.idproofs"
                                class="textField1"
                              >
                                {{ guestItem1.idproofs.idNumber }}
                              </v-flex>
                              <v-flex xs12 v-else class="textField1">
                                Nil
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card>
              </v-flex>
              <v-flex xs12 md12 py-2>
                <v-layout wrap justify-center>
                  <v-flex xs6 sm6 md6 lg6 text-left>
                    <span style="font-size: 16px; font-family: poppinssemibold"
                      >Payment date :</span
                    >
                  </v-flex>
                  <v-flex xs6 sm6 md6 lg6>
                    <span
                      style="
                        font-size: 16px;
                        font-family: poppinsbold;
                        color: #68d389;
                      "
                    >
                       {{ items.data.init_date.slice(0, 10) }}</span
                    >
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-center>
                  <v-flex xs6 sm6 md6 lg6 text-left>
                    <span style="font-size: 16px; font-family: poppinssemibold"
                      >Total Amount :</span
                    >
                  </v-flex>
                  <v-flex xs6 sm6 md6 lg6>
                    <span
                      style="
                        font-size: 16px;
                        font-family: poppinsbold;
                        color: #68d389;
                      "
                    >
                      INR {{ items.data.total }}</span
                    >
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        <!-- </router-link> -->
      </v-flex>
    </v-layout>
      </v-flex>
      <!-- <v-flex xs12 md12 pt-4>
        <span
          style="font-family: poppinsbold; font-size: 18px; color: #53a874"
        >
          Ticket #{{ item.ticket.ticketNo }}
        </span>
      </v-flex>
      <v-flex xs12 md12>
        <v-card outlined>
          <v-layout wrap justify-center>
            <v-flex xs12>
              <v-layout wrap pa-2>
                <v-flex xs4 lg2>
                  <v-img
                    v-if="item.programme.photos.length > 0"
                    width="175px"
                    height="113px"
                    :src="mediaURL + item.programme.photos[0]"
                  >
                    <template v-slot:placeholder>
                      <ImageLoader />
                    </template>
                  </v-img>
                  <v-img
                    v-else
                    width="175px"
                    height="113px"
                  src="../../assets/images/no-image.png"
                  >
                    <template v-slot:placeholder>
                      <ImageLoader />
                    </template>
                  </v-img>
                </v-flex>
                <v-flex xs8 lg10 pa-2>
                  <v-layout wrap justify-start>
                    <v-flex xs12 text-left>
                      <span class="itemCaption" style="font-size: 18px">
                        {{ item.programme.progName }}
                      </span>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex md2 text-left>
                      <span class="pageForm"> Selected Date</span><br />
                      <span class="cardSubHeading" style="letter-spacing: 0px"
                        >{{ formatDate(item.bookingDate) }}
                      </span>
                    </v-flex>
                    <v-flex md3 text-left>
                      <span class="pageForm"> Selected Slot</span><br />
                      <span class="cardSubHeading" style="letter-spacing: 0px">
                        {{ formatTime(item.slotDetail.startTime) }} -
                        {{ formatTime(item.slotDetail.endTime) }}
                      </span>
                    </v-flex>
                    <v-flex md4 text-left>
                      <span class="pageForm"> Paid By</span><br />
                      <span class="cardSubHeading"  style="letter-spacing: 0px">
                        {{ item.user.email }}
                      </span>
                      <br />
                      <span class="cardSubHeading" style="letter-spacing: 0px">
                        {{ item.user.phone }}
                      </span>
                    </v-flex>
                    <v-flex md2 text-center v-if="item.isCottage">
                      <span class="pageForm"> Rooms Required</span><br />
                      <span class="cardSubHeading" style="letter-spacing: 0px">
                        {{ item.numberOfRooms }}
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-divider></v-divider>
              <v-layout wrap pa-2>
                <v-flex py-2 xs12 v-for="(items, i) in item.guest" :key="i">
                  <v-layout wrap>
                    <v-flex md2 text-capitalize>
                      <span class="pageForm" style="font-size: 14px">
                        {{ items.name }}
                      </span>
                    </v-flex>
                    <v-flex md3>
                      <span class="pageForm" style="font-size: 14px">
                        {{ items.email }}
                      </span>
                    </v-flex>

                    <v-flex md2>
                      <span class="pageForm" style="font-size: 14px">
                        {{ items.phoneno }}
                      </span>
                    </v-flex>
                    <v-flex md1>
                      <span class="pageForm" style="font-size: 14px">
                        {{ items.gender }}
                      </span>
                    </v-flex>
                    <v-flex md2>
                      <span class="pageForm" style="font-size: 14px">
                        {{ formatDate(items.dob) }}
                      </span>
                    </v-flex>
                    <v-flex md1>
                      <span class="pageForm" style="font-size: 14px">
                        {{ items.nationality }}
                      </span>
                    </v-flex>
                    <v-flex md1>
                      <span
                        v-if="items.nationality == 'Indian'"
                        class="pageForm"
                        style="font-size: 14px"
                      >
                        No Id Proof
                      </span>
                      <span v-else class="pageForm" style="font-size: 14px">
                        {{ items.idproof }}
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-divider></v-divider>
              <v-layout wrap pa-2>
                <v-flex md4>
                  <span class="textField2" style="font-size: 14px">
                    Program Total
                  </span>
                </v-flex>
                <v-flex md7 text-right align-self-end>
                  <span style="font-family:poppinssemibold; font-size: 16px">
                    {{ item.programTotal }} INR
                  </span>
                </v-flex>
              </v-layout>
              <v-divider></v-divider>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex> -->
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#68D389"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["bookingData", "pages","fromDate","toDate"],
  // props: ["fromDate","toDate","programme"],
  data() {
    return {
      currentPage: 1,
      // pages: 0,
      // bookingData: [],
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
    };
  },
  // watch: {
  //   currentPage() {
  //     this.winStepper();
  //   },
  // },

  watch: {
    fromDate() {
      // this.getData()
    },
    toDate() {
      // this.getData()
    },
    programme() {
      // this.getData()
    },
    currentPage() {
      // this.getData();
      this.winStepper();
    },
  },
  beforeMount() {
    // this.getData()
    this.winStepper();
  },
  methods: {
    winStepper() {
      // console.log("step", this.currentPage);
      // alert("my call win comp")
      this.$emit("stepper", {
        getData: true,
        currentPage: this.currentPage,
        pages: this.pages,
        type: "Booking",
      });
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/booking/agentbookingreport",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          from: this.fromDate,
          to: this.toDate,
          user: this.programme,
          programme: this.programmee,
          // slotDetail: this.slotDetail,
          count: 12,
          page: this.currentPage,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.bookingData = response.data.data;
            // this.reservationData = response.data.reservations;
            this.pages = response.data.pages;

            // this.excel = response.data.data;
            // this.json_data = this.excel;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
    formatTime(item) {
      if (!item) return;
      var hours = Number(item.split(":")[0]);
      var minutes = Number(item.split(":")[1]);
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
  },
};
</script>